import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import ImageHero from '../../generic/ImageHero';
import TabBar from '../../generic/tabBar/TabBar';
import ClientSideTab from '../../generic/tabBar/ClientSideTab';
import LastOnTvTab from './LastOnTvTab';
import EpisodesTab from './EpisodesTab';
import RecipesTab from './RecipesTab';
import MetaProperties from '../../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({ tabs, id, title, description, image }) => (
  <>
    <MetaProperties
      title={title}
      description="Quickly find recipes from your favourite current BBC programmes, or browse the archive of BBC recipes from shows gone by."
      imageUrl={image}
    />
    <ImageHero title={title} description={description} imageUrl={image} />
    <TabBar>
      {tabs.lastOnTv && <ClientSideTab href={`/food/programmes/${id}`}>Last on TV</ClientSideTab>}
      {tabs.episodes && (
        <ClientSideTab href={`/food/programmes/${id}/episodes`}>Episodes</ClientSideTab>
      )}
      {tabs.recipes && (
        <ClientSideTab href={`/food/programmes/${id}/recipes`}>Recipes</ClientSideTab>
      )}
    </TabBar>
    <Route exact path={`/food/programmes/${id}`} component={LastOnTvTab} />
    <Route exact path={`/food/programmes/${id}/episodes`} component={EpisodesTab} />
    <Route exact path={`/food/programmes/${id}/recipes`} component={RecipesTab} />
  </>
);

Page.propTypes = {
  tabs: PropTypes.shape({
    lastOnTv: PropTypes.bool,
    recipes: PropTypes.bool,
    episodes: PropTypes.bool,
  }),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

Page.defaultProps = {
  tabs: {
    lastOnTv: true,
    recipes: true,
    episodes: true,
  },
};

export default connect(({ brandReducer }) => ({
  tabs: brandReducer.tabs,
  id: brandReducer.id,
  title: brandReducer.title,
  description: brandReducer.description,
  image: brandReducer.image,
}))(Page);
