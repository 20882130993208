import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import StandardCollection12Promos from '../../promo/collections/StandardCollection12Promos';
import Pagination from '../../generic/pagination/Pagination';
import PaginationSummary from '../../generic/pagination/PaginationSummary';
import LoadingOverlay from '../../generic/LoadingOverlay';
import FocusTarget from '../../generic/FocusTarget';
import ErrorMessage from '../../generic/message/ErrorMessage';
import { scrollIntoView } from '../../generic/scrollIntoView';
import { fetchRecipes } from './actions';
import { promoShape } from '../../promo/shapes';

export class RecipesTab extends Component {
  focusRef = React.createRef();

  componentDidMount() {
    this.fetchRecipes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchRecipes();
      scrollIntoView(this.focusRef.current);
      this.focusRef.current.focus();
    }
  }

  fetchRecipes = () => {
    const { id, location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page') || 1, 10);
    this.props.fetchRecipes(id, page);
  };

  render() {
    const { id, title, recipes, page, totalCount, loading, error } = this.props;

    return (
      <LoadingOverlay active={loading} className="programme-brand__recipes-tab">
        <Helmet>
          <title>{`${title} recipes`}</title>
        </Helmet>
        {!error ? (
          <>
            <FocusTarget ref={this.focusRef} />
            {totalCount > 0 && (
              <PaginationSummary page={page} pageSize={24} totalCount={totalCount}>
                recipes
              </PaginationSummary>
            )}
            <StandardCollection12Promos
              id="programmes-recipes-collection"
              maxCollectionSize={24}
              promos={recipes}
            />
            {totalCount > 24 && (
              <Pagination
                clientSide
                page={page}
                pages={Math.ceil(totalCount / 24)}
                buildUrl={p => `/food/programmes/${id}/recipes?page=${p}`}
              />
            )}
          </>
        ) : (
          <div className="gel-wrap">
            <ErrorMessage retry={this.fetchRecipes} />
          </div>
        )}
      </LoadingOverlay>
    );
  }
}

RecipesTab.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  fetchRecipes: PropTypes.func,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  location: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

RecipesTab.defaultProps = {
  fetchRecipes: () => {},
  loading: false,
  error: false,
  recipes: [],
  page: 1,
  totalCount: 0,
};

export default connect(
  ({ brandReducer }) => ({
    id: brandReducer.id,
    title: brandReducer.title,
    loading: brandReducer.recipes.loading,
    error: brandReducer.recipes.error,
    recipes: brandReducer.recipes.recipes,
    page: brandReducer.recipes.page,
    totalCount: brandReducer.recipes.totalCount,
  }),
  {
    fetchRecipes,
  }
)(RecipesTab);
