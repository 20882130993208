import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Tab from './Tab';

const ClientSideTab = ({ href, ...props }) => (
  <Tab
    tag={NavLink}
    to={href}
    exact
    activeClassName="tab-bar__tab--selected gel-pica-bold"
    {...props}
  />
);

ClientSideTab.propTypes = {
  href: PropTypes.string,
};

ClientSideTab.defaultProps = {
  href: undefined,
};

export default ClientSideTab;
