import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import StandardHeroCollection from '../../promo/collections/StandardHeroCollection';
import LoadingOverlay from '../../generic/LoadingOverlay';
import ErrorMessage from '../../generic/message/ErrorMessage';
import { fetchLastOnTv } from './actions';
import { promoShape } from '../../promo/shapes';

export class LastOnTvTab extends Component {
  componentDidMount() {
    this.fetchLastOnTv();
  }

  fetchLastOnTv = () => {
    this.props.fetchLastOnTv(this.props.id);
  };

  render() {
    const { title, recentEpisodes, loading, error } = this.props;
    return (
      <LoadingOverlay active={loading} className="programme-brand__recent-episodes-tab">
        <Helmet>
          <title>{`${title} last on TV`}</title>
        </Helmet>
        <div className="gel-wrap">
          {!error ? (
            <StandardHeroCollection promos={recentEpisodes} maxCollectionSize={24} />
          ) : (
            <ErrorMessage retry={this.fetchLastOnTv} />
          )}
        </div>
      </LoadingOverlay>
    );
  }
}

LastOnTvTab.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  recentEpisodes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  fetchLastOnTv: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

LastOnTvTab.defaultProps = {
  fetchLastOnTv: () => {},
  loading: false,
  error: false,
  recentEpisodes: [],
};

export default connect(
  ({ brandReducer }) => ({
    id: brandReducer.id,
    title: brandReducer.title,
    loading: brandReducer.recentEpisodes.loading,
    error: brandReducer.recentEpisodes.error,
    recentEpisodes: brandReducer.recentEpisodes.recentEpisodes,
  }),
  {
    fetchLastOnTv,
  }
)(LastOnTvTab);
