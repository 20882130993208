import {
  SET_BRAND_DATA,
  FETCH_LAST_ON_TV_REQUEST,
  FETCH_LAST_ON_TV_SUCCESS,
  FETCH_LAST_ON_TV_FAILURE,
  FETCH_EPISODES_REQUEST,
  FETCH_EPISODES_SUCCESS,
  FETCH_EPISODES_FAILURE,
  FETCH_RECIPES_REQUEST,
  FETCH_RECIPES_SUCCESS,
  FETCH_RECIPES_FAILURE,
} from './constants';

export const setBrandData = props => ({
  type: SET_BRAND_DATA,
  ...props,
});

export const fetchLastOnTvRequest = () => ({
  type: FETCH_LAST_ON_TV_REQUEST,
});

export const fetchLastOnTvSuccess = recentEpisodes => ({
  type: FETCH_LAST_ON_TV_SUCCESS,
  recentEpisodes,
});

export const fetchLastOnTvFailure = () => ({
  type: FETCH_LAST_ON_TV_FAILURE,
});

export const fetchLastOnTv = id => async dispatch => {
  dispatch(fetchLastOnTvRequest());

  await fetch(`/food/api/programmes/${id}/last-on-tv`)
    .then(response => response.json())
    .then(json => dispatch(fetchLastOnTvSuccess(json)))
    .catch(() => dispatch(fetchLastOnTvFailure()));
};

export const fetchEpisodesRequest = () => ({
  type: FETCH_EPISODES_REQUEST,
});

export const fetchEpisodesSuccess = ({ episodes, page, totalCount }) => ({
  type: FETCH_EPISODES_SUCCESS,
  episodes,
  page,
  totalCount,
});

export const fetchEpisodesFailure = () => ({
  type: FETCH_EPISODES_FAILURE,
});

export const fetchEpisodes = (id, page) => async dispatch => {
  dispatch(fetchEpisodesRequest());

  await fetch(`/food/api/programmes/${id}/episodes?page=${page}`)
    .then(response => response.json())
    .then(json => dispatch(fetchEpisodesSuccess(json)))
    .catch(() => dispatch(fetchEpisodesFailure()));
};

export const fetchRecipesRequest = () => ({
  type: FETCH_RECIPES_REQUEST,
});

export const fetchRecipesSuccess = ({ recipes, page, totalCount }) => ({
  type: FETCH_RECIPES_SUCCESS,
  recipes,
  page,
  totalCount,
});

export const fetchRecipesFailure = () => ({
  type: FETCH_RECIPES_FAILURE,
});

export const fetchRecipes = (id, page) => async dispatch => {
  dispatch(fetchRecipesRequest());

  await fetch(`/food/api/programmes/${id}/recipes?page=${page}`)
    .then(response => response.json())
    .then(json => dispatch(fetchRecipesSuccess(json)))
    .catch(() => dispatch(fetchRecipesFailure()));
};
