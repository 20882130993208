import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BrandPage from './brand/Page';
import EpisodePage from './episode/Page';

export const Page = ({ isEpisode, ...props }) =>
  isEpisode ? <EpisodePage {...props} /> : <BrandPage {...props} />;

Page.propTypes = {
  isEpisode: PropTypes.bool.isRequired,
};

export default connect(({ programmeReducer }) => ({
  isEpisode: programmeReducer.isEpisode,
}))(Page);
