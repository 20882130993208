import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentSeparator from '../../generic/ContentSeparator';
import EpisodeInfo from '../../generic/EpisodeInfo';
import Paragraphs from '../../generic/Paragraphs';
import PageHeader from '../../generic/PageHeader';
import SeeAllRecipesLink from '../../generic/SeeAllRecipesLink';
import Promo from '../../promo/Promo';
import { CollectionDate } from '../../promo/Collection';
import StandardCollection12Promos from '../../promo/collections/StandardCollection12Promos';
import EpisodeHero from './EpisodeHero';
import { promoShape } from '../../promo/shapes';
import MetaProperties from '../../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({
  pid,
  title,
  promoTitle,
  promoSubtitle,
  releaseDate,
  image,
  shortDescription,
  mediumDescription,
  description,
  duration,
  availableOnIPlayer,
  recipes,
  chefs,
  brandPid,
  brandTitle,
  totalCount,
  label,
  episodeNumber,
  seriesNumber,
}) => (
  <div className="episode-page">
    <MetaProperties
      title="Programmes"
      description={mediumDescription || shortDescription}
      imageUrl={image}
      imageWidth="1088"
      imageHeight="612"
    />
    <PageHeader title={title}>
      {episodeNumber && (
        <EpisodeInfo
          className="gel-pica page-header__subtitle"
          episodeNumber={episodeNumber}
          seriesNumber={seriesNumber}
          tag="h2"
        />
      )}
      {releaseDate && <CollectionDate>{releaseDate}</CollectionDate>}
    </PageHeader>
    <div className="gel-wrap">
      <div className="gel-layout gel-layout--center">
        <div className="gel-layout__item gel-1/1 gel-10/12@xxl">
          {availableOnIPlayer ? (
            <Promo
              extraClasses={{
                promo: 'episode-page__promo',
              }}
              duration={duration}
              id={pid}
              imageId={image}
              playIconClass={!label && 'iplayer iplayer--hero'}
              title={promoTitle}
              titleClassName="gel-double-pica-bold"
              subtitle={promoSubtitle}
              url={`/iplayer/episode/${pid}`}
              label={label}
              labelClassName="gel-pica"
            />
          ) : (
            image && <EpisodeHero imageId={image} title={promoTitle} subtitle={promoSubtitle} />
          )}
          {description && (
            <div className="episode-description gel-wrap">
              <div className="gel-layout">
                <Paragraphs
                  text={description}
                  className="gel-body-copy gel-layout__item gel-3/4@l"
                />
              </div>
            </div>
          )}
          {brandPid && (
            <div className="gel-wrap">
              <SeeAllRecipesLink
                body={`all episodes from ${brandTitle}`}
                href={`/food/programmes/${brandPid}`}
                totalCount={totalCount}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    {recipes.length > 0 && (
      <>
        <ContentSeparator />
        <StandardCollection12Promos
          id="programme-episode-recipes"
          title="Recipes from this episode"
          promos={recipes}
          maxCollectionSize={64}
        />
        <ContentSeparator />
      </>
    )}
    {chefs.length > 0 && (
      <StandardCollection12Promos
        id="programme-episode-chefs"
        title="Related chefs"
        promos={chefs}
        maxCollectionSize={24}
      />
    )}
  </div>
);

Page.propTypes = {
  pid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  promoTitle: PropTypes.string.isRequired,
  promoSubtitle: PropTypes.string,
  shortDescription: PropTypes.string,
  mediumDescription: PropTypes.string,
  description: PropTypes.string.isRequired,
  duration: PropTypes.string,
  image: PropTypes.string,
  releaseDate: PropTypes.string,
  availableOnIPlayer: PropTypes.bool.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  chefs: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  brandPid: PropTypes.string,
  brandTitle: PropTypes.string,
  totalCount: PropTypes.number,
  label: PropTypes.string,
  episodeNumber: PropTypes.number,
  seriesNumber: PropTypes.number,
};

Page.defaultProps = {
  duration: undefined,
  releaseDate: undefined,
  promoSubtitle: undefined,
  image: undefined,
  recipes: [],
  chefs: [],
  brandPid: undefined,
  brandTitle: undefined,
  shortDescription: undefined,
  mediumDescription: undefined,
  totalCount: undefined,
  label: undefined,
  episodeNumber: undefined,
  seriesNumber: undefined,
};

export default connect(({ episodeReducer }) => ({
  pid: episodeReducer.pid,
  title: episodeReducer.title,
  promoTitle: episodeReducer.promoTitle,
  promoSubtitle: episodeReducer.promoSubtitle,
  shortDescription: episodeReducer.shortDescription,
  mediumDescription: episodeReducer.mediumDescription,
  description: episodeReducer.description,
  duration: episodeReducer.duration,
  image: episodeReducer.image,
  releaseDate: episodeReducer.releaseDate,
  availableOnIPlayer: episodeReducer.availableOnIPlayer,
  recipes: episodeReducer.recipes,
  chefs: episodeReducer.chefs,
  brandPid: episodeReducer.brandPid,
  brandTitle: episodeReducer.brandTitle,
  totalCount: episodeReducer.totalCount,
  label: episodeReducer.label,
  episodeNumber: episodeReducer.episodeNumber,
  seriesNumber: episodeReducer.seriesNumber,
}))(Page);
