import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Image from '../../generic/Image';

if (process.env.CLIENT) {
  require('./EpisodeHero.scss'); // eslint-disable-line global-require
}

const EpisodeHero = ({ imageId, subtitle, title }) => {
  const noImage = isEmpty(imageId);

  return (
    <div className="episode-hero">
      <div className="gel-layout gel-layout--flush episode-hero__text__wrap">
        {imageId && (
          <div className="episode-hero__image-container gel-layout__item">
            <Image className="episode-hero__image" src={imageId} />
          </div>
        )}
        <div className="gel-layout__item episode-hero__text">
          <div className="gel-wrap">
            <div className="gel-layout">
              <div className="gel-layout__item episode-hero__title-container">
                <h3
                  className={classNames(
                    'episode-hero__title',
                    noImage
                      ? 'episode-hero__title-no-image gel-double-pica'
                      : 'gel-double-pica-bold'
                  )}
                >
                  {title}
                </h3>
                {subtitle && (
                  <span className="episode-hero__subtitle gel-long-primer">{subtitle}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EpisodeHero.propTypes = {
  imageId: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

EpisodeHero.defaultProps = {
  imageId: undefined,
  subtitle: undefined,
};

export default EpisodeHero;
